import { HTTP } from "./Connection"

export class PurchaseOrderItemService {

  static update (purchase_order_item) {
    return new Promise((resolve, reject) => {
      const id = purchase_order_item.id
      HTTP.put("/api/purchase_order_items/" + id, purchase_order_item).then(resolve, reject)
    })
  }

  static destroy (id) {
    return new Promise((resolve, reject) => {
      HTTP.delete("/api/purchase_order_items/" + id).then(resolve, reject)
    })
  }

  static create (purchase_order_item) {
    return new Promise((resolve, reject) => {
      const url = "/api/purchase_order_items"
      HTTP.post(url, purchase_order_item).then(resolve, reject)
    })
  }
}
