import React from "react"
import Select, { components } from 'react-select';
import { asCurrency } from "../../services/Utils"
import { debounce } from "lodash"

const Option = (innerProps) => {
  const option = innerProps.data
  let availableIcon = ''
  if ( !option.published ) {
    availableIcon = (
      <span className='badge badge-pill badge-danger'>
        <span className="fa fa-exclamation-triangle mr-1"></span>
        Currently Unavailable
      </span>
     )
  } else if ( option.next_availability_description ) {
   availableIcon = (
    <span className='badge badge-pill badge-warning'>
      <span className="fa fa-clock mr-1"></span>
      { option.next_availability_description }
    </span>
   )
  }

  const mutedUnavailable = option.published ? "select-option" : "select-option text-muted"
  let minimum
  if (option.quantity_minimum > 1) {
    minimum = <span className="badge border ml-2">{`${option.quantity_minimum} minimum`}</span>
  }
  let city_state_tag
  if (option.city && option.state) {
    city_state_tag = (

      <small>
        <span className='mx-2'>•</span>
        {option.city}, {option.state}
      </small>
    )
  }

  const strikethroughClass = option.discount_price ? 'text-muted small text-strike' : ''
  let discountPrice;
  if ( option.discount_price ) {
    discountPrice = <span className='ml-1'>{ asCurrency(option.discount_price)}</span>
  }
  const children = (
    <div className={mutedUnavailable}>
      <div className='d-flex'>
        <div className='mr-2'>
          <img src={option.product_photo.small_url} height="60" />
        </div>
        <div className='w-100'>
          <div className="text-muted">
            <small>{option.producer_name}</small>
            { city_state_tag }
          </div>
          <div className='d-flex justify-content-between'>
            <div>
              <div className="font-weight-bold">{option.name}</div>
              <div>{ option.unit }{ minimum }</div>
            </div>
            <div className="text-right">
              <div>{ availableIcon }</div>
              <span className={strikethroughClass}>{ asCurrency(option.price) }</span>
              { discountPrice }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  return <components.Option {...innerProps} children={ children } />
}

const Input = (innerProps) => {
  return <components.Input {...innerProps} name="product-unit" />
}

const SingleValue = (innerProps) => {
  const option = innerProps.data
  let minimum

  if (!option) {
    return (<components.SingleValue {...innerProps} children={ <span>Nothing</span> }/>)
  }

  if (option.quantity_minimum > 1) {
    minimum = <span className="badge border ml-2">{`${option.quantity_minimum} minimum`}</span>
  }
  const children = (
    <span>
      <span className='small font-weight-muted mr-2'>{ option.producer_name }</span>
      <span className="font-weight-bold mr-2">{ option.name }</span>
      <span className="mr-4">({ option.unit })</span>
      { minimum }
    </span>
  )
  return <components.SingleValue {...innerProps} children={ children }/>
}


export class ProductUnitSelect extends React.Component {
  constructor(props) {
    super(props)

    this.searchInput = React.createRef()
    this.refocus = this.refocus.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.search = debounce(this.search, 500)

    this.state = { inputValue: "", options: [] }
  }

  onChange (product_unit) {
    this.props.onProductUnitSelected(product_unit)
  }

  search() {
    if (!this.state.inputValue) { this.setState({ options: [] }); return }
    let params = {
       text: this.state.inputValue,
       categories: [],
       producers: []
    }
    if ( this.props.buyer_id )
      params.buyerId = this.props.buyer_id

    this.setState({ isLoading: true })
    this.props.fetcher(this.props.seller_id, 1, 25, params)
      .then((products) => { this.setState({ options: products, isLoading: false }) })
  }


  onInputChange(input) {
    this.setState({ inputValue: input }, this.search)
  }

  filterOption(option) {
    // Do no filtering, just return all options
    return true
  }

  noOptionsMessage(option) {
    return option.inputValue ? "No options" : "Type to see product units..."
  }

  refocus() {
    this.searchInput.current.focus()
  }

  render () {
    return (
      <Select
        ref={ this.searchInput }
        value={ this.props.value }
        components={{ Option: Option, SingleValue: SingleValue, Input: Input }}
        placeholder="Search for products..."
        noOptionsMessage={ this.noOptionsMessage }
        onChange={ this.onChange }
        inputValue={ this.state.inputValue }
        isOptionSelected={ () => {false} }
        onInputChange={ this.onInputChange }
        filterOption={ this.filterOption }
        maxMenuHeightnumber={400}
        isLoading={ this.state.isLoading }
        isClearable={ true }
        options={ this.state.options }
      />
    );
  }
}
export default ProductUnitSelect