import React from "react"
import { asCurrency } from "../../services/Utils"

export class ProductUnitDeposit extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const product_unit = this.props.product_unit
    if (Number(product_unit.deposit) == 0) { return '' }
    return (
      <div className='deposit text-muted mt-2'>
        <span className='fa fa-recycle mr-1'></span>
        { `+${ asCurrency(product_unit.deposit) } refundable deposit` }
      </div>
    )
  }
}
