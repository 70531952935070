document.addEventListener('stripe:setup-add-card', () => {
  if ( $('#seller-add-card-element').data('pk') ) {
    const published_key = $('#seller-add-card-element').data('pk')
    const stripe = Stripe(published_key);

    const cardOptions = {
      iconStyle: 'solid',
      hidePostalCode: true,
      style: {
        base: {
          fontSize: '17.5px',
          fontWeight: 400,
          fontFamily: 'Helvetica Neue',
          color: '#495057',
          backgroundColor: '#fff',
          borderRadius: '0.25rem'
        }
      }
    }

    var elements = stripe.elements();
    var cardElement = elements.create('card', cardOptions);
    cardElement.mount('#seller-add-card-element');

    var cardButton = document.getElementById('seller-add-card-button');
    var clientSecret = cardButton.dataset.secret;

    cardButton.addEventListener('click', function(ev) {
      const cardholderName = document.getElementById('cardholder-name');
      const payload = {
        payment_method: {
          card: cardElement,
          billing_details: { name: cardholderName.value }
        }
      }
      $('#stripe-error-message').slideUp();
      $('#stripe-error-message .alert').text('')
      $(cardButton).text('Submitting...');
      $(cardButton).prop('disabled', true);

      stripe.confirmCardSetup(
        clientSecret,
        payload
      ).then(function(result) {
        if (result.error) {
          $('#stripe-error-message .alert').text(result.error.message)
          $('#stripe-error-message').slideDown();
          $(cardButton).text('Add Card');
          $(cardButton).prop('disabled', false);
        } else {
          $('#add-new-card').slideUp(400, () => {
            const confirmation = `
              <h3>Card Added</h3>
            `
            $('#add-new-card').html(confirmation);
            $('#add-new-card').slideDown();
          });
        }
      });
      return event.preventDefault();
    });
  }
})
