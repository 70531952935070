import React from "react"

// a smart input for hnits of a product
// Usage; <QuantityInput onChange={fcn(unit, quantity)} id={string} unitId={int} initial={int}/>
export class QuantityInput extends React.Component {
  constructor(props) {
    super(props)

    this.upperLimit = props.limit || 100

    this.state = {
      value: props.initial || ""
    }
  }

  handleChange(event) {
    const newval = parseInt(event.target.value)

    if ((!isNaN(newval) && newval <= this.upperLimit) || newval === "") {
      this.setState({
        value: newval
      })

      if (this.props.onChange && newval > 0)
        this.props.onChange(this.props.unitId, newval)
    } else {
      this.setState({})
    }
  }

  reset() {
    this.setState({
      value: "0"
    })
  }

  render() {
    var options = []

    for (var i = this.props.minimum || 1; i < this.upperLimit; i++) {
      options.push(
        <option value={i} key={"option_" + i}>
          {i}
        </option>
      )
    }

    return (
      <select
        value={this.state.value}
        id={this.props.id || ""}
        name="quantity"
        className="form-control text-center px-1"
        style={{width: '50px'}}
        onChange={this.handleChange.bind(this)}
      >
        {options}
      </select>
    )
  }
}
