document.addEventListener("turbo:load", function(){
  var datatable = $('table.datatable-init').DataTable({ stateSave: true })
  $('#outside-filter-input').on('keyup', function() {
    datatable.api().search($(this).val()).draw();
  });

  document.addEventListener("turbo:before-cache", function() {
    datatable.api().destroy()
  })
});
