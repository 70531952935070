document.addEventListener('turbo:load', () => {
  const inputs = document.querySelectorAll('#deposits-list input[type=checkbox]');

  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i];

    input.addEventListener('change', (event) => {
      const totalElement = document.querySelector('#deposit-total')
      const total = parseFloat(totalElement.innerHTML.replace(/[^0-9.-]+/g,""))
      let change = Number(event.target.getAttribute('data-value'))

      if (!event.target.checked) { change = -change }
      const newTotal = total + change

      const formatted = newTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      totalElement.innerHTML = formatted
    });
  }
})
