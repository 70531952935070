import { HTTP } from "./Connection"

export class CatalogFetcher {
  static queryPublic(sellerId, page, batchSize, params) {
    const base = "/api/seller/" + sellerId + '/public_products?'
    return CatalogFetcher.query(base, page, batchSize, params)
  }

  static queryAll(sellerId, page, batchSize, params) {
    const base = "/api/sellers/" + sellerId + "/products/with_unavailable?"
    return CatalogFetcher.query(base, page, batchSize, params)
  }

  static queryCatalog(sellerId, catalogId, page, batchSize, params) {
    params.catalogId = catalogId
    const base = "/api/sellers/" + sellerId + "/products/?"
    return CatalogFetcher.query(base, page, batchSize, params)
  }

  static queryAvailable(sellerId, page, batchSize, params) {
    const base = "/api/sellers/" + sellerId + "/products/?"
    return CatalogFetcher.query(base, page, batchSize, params)
  }

  static query(base, page, batchSize, params) {
    let buyer = ""
    let privateCatalog = ""
    let categoriesSearch = ""
    let collectionsSearch = ""
    let producersSearch = ""
    let textSearch = ""
    let productSlug = ""
    let count = ""
    let localOnly = ""
    let isStocked = ""
    let sortBy = ""
    let isFeatured = ""

    if ( params.categories && params.categories.length > 0 )
      categoriesSearch = "&categories=" + params.categories.join("|")
    if ( params.producers && params.producers.length > 0  )
      producersSearch = "&producers=" + params.producers.join("|")
    if ( params.collections && params.collections.length > 0  )
      collectionsSearch = "&collections=" + params.collections.join("|")
    if ( params.count )
      count = "&count=true"
    if ( params.localOnly )
      localOnly = "&local_only=true"
    if ( params.buyerId )
      buyer = "&buyer_id=" + params.buyerId
    if ( params.catalogId )
      privateCatalog = "&private_catalog_id=" + params.catalogId
    if ( params.isStocked )
      isStocked = "&is_stocked=true"
    if ( params.isFeatured )
      isFeatured = "&featured=true"
    if ( params.productSlug )
      productSlug = "&product_slug=" + encodeURIComponent(params.productSlug)
    if ( params.text )
      textSearch = textSearch + "&text=" + encodeURIComponent(params.text)
    if ( params.sortBy )
      sortBy = sortBy + "&sort_by=" + params.sortBy

    return HTTP.get(
      base +
        "page=" +
        page +
        "&per_page=" +
        batchSize +
        categoriesSearch +
        collectionsSearch +
        privateCatalog +
        buyer +
        producersSearch +
        localOnly +
        isStocked +
        isFeatured +
        textSearch +
        productSlug +
        sortBy +
        count
    )
  }
}
