document.addEventListener('turbo:load', () => {
  if ( $('#seller-add-bank-account').data('pk') ) {
    const published_key = $('#seller-add-bank-account').data('pk')
    const stripe = Stripe(published_key);

    const bankAccountButton = document.getElementById('bank-account-button');
    bankAccountButton.addEventListener('click', function(ev) {
      const routing_number =  document.getElementById('routing_number').value
      const account_number =  document.getElementById('account_number').value
      const account_holder_name = document.getElementById('account_holder_name').value
      const account_holder_type = document.querySelector('input[name="account_holder_type"]:checked').value


      stripe.createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        routing_number: routing_number,
        account_number: account_number,
        account_holder_name: account_holder_name,
        account_holder_type: account_holder_type
      })
      .then(function(result) {
        if (result.token) {
          const tokenInput = document.getElementById('stripe_bank_account_token_id')

          tokenInput.value = result.token.id

          const form = document.getElementById('stripe-bank-account-form').submit()
        } else {
          $('#add-bank-account-errors .alert').text(result.error.message)
          $('#add-bank-account-errors').slideDown();
        }
      });
    })
  }
})
