export class HTTP {
  static handleResponse(status, success, failure) {
    if (status >= 200 && status < 300) {
      success()
    } else if (
      (status >= 300 && status < 400) ||
      status == 401 ||
      status == 403
    ) {
      window.location.href = "/users/sign_in?return_to=/app/"
    } else {
      failure()
    }
  }

  static get(url) {
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest()
      xhr.open("GET", url, true)
      xhr.onload = function() {
        var status = xhr.status
        HTTP.handleResponse(
          status,
          () => {
            resolve(JSON.parse(xhr.responseText))
          },
          () => {
            reject(JSON.parse(xhr.responseText))
          }
        )
      }
      xhr.send()
    })
  }

  static post(url, message) {
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest()
      xhr.open("POST", url, true)
      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onload = function() {
        let status = xhr.status

        HTTP.handleResponse(
          status,
          () => {
            resolve(JSON.parse(xhr.responseText))
          },
          () => {
            reject(JSON.parse(xhr.responseText))
          }
        )
      }

      xhr.send(JSON.stringify(message))
    })
  }

  static delete(url, message) {
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest()
      xhr.open("DELETE", url, true)
      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onload = function() {
        let status = xhr.status

        HTTP.handleResponse(
          status,
          () => {
            resolve(JSON.parse(xhr.responseText || null))
          },
          () => {
            reject(JSON.parse(xhr.responseText))
          }
        )
      }

      xhr.send()
    })
  }

  static put(url, message) {
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest()
      xhr.open("PUT", url, true)
      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onload = function() {
        let status = xhr.status
        HTTP.handleResponse(
          status,
          () => {
            resolve(JSON.parse(xhr.responseText || null))
          },
          () => {
            reject(JSON.parse(xhr.responseText))
          }
        )
      }

      xhr.send(JSON.stringify(message))
    })
  }
}
