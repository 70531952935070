import React from "react"
import { asCurrency } from "../../services/Utils"
import { SalesOrderItemService } from "../../services/SalesOrderItemService"
import { QuantitySelect } from "./QuantitySelect"
import { ProductUnitSelect } from "./ProductUnitSelect"
import { CatalogFetcher } from "../../services/CatalogFetcher"

export class SalesOrderItemNew extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.onCreate = this.onCreate.bind(this)
    this.productUnitSelect = React.createRef()
    this.addButton = React.createRef()
    this.quantitySelect = React.createRef()
    this.onChangeQuantity = this.onChangeQuantity.bind(this)
    this.onProductUnitSelected = this.onProductUnitSelected.bind(this)
    this.notifyParentOfSubtotalChange = this.notifyParentOfSubtotalChange.bind(this)

    const initialQuantity = this.props.sales_order.credit ? -1 : 1
    this.state = {
      sales_order_item: { quantity: initialQuantity, buyer_order_id: this.props.sales_order.id }
    }
  }


  onCreate() {
    let sales_order_item = this.state.sales_order_item
    sales_order_item.src = 'sales_order_item_new'
    SalesOrderItemService.create(sales_order_item).then((new_sales_order_item) => {
      this.props.createdSalesOrderItem(new_sales_order_item)
      const initialQuantity = this.props.sales_order.credit ? -1 : 1
      this.setState({
        sales_order_item: { quantity: initialQuantity, buyer_order_id: this.props.sales_order.id },
        product_unit: null
      }, () => { this.quantitySelect.current.refocus() })
    })
  }

  toggle(event) {
    this.setState({ showUnavailable: !this.state.showUnavailable })
  }

  notifyParentOfSubtotalChange() {
    this.props.onSubtotalUpdate(this.calculateSubtotal())
  }

  onChangeQuantity(newValue) {
    let sales_order_item = this.state.sales_order_item
    sales_order_item.quantity = newValue
    this.setState({ sales_order_item: sales_order_item }, this.notifyParentOfSubtotalChange)
  }

  onProductUnitSelected(product_unit) {
    let sales_order_item = this.state.sales_order_item
    if (!product_unit) {
      delete sales_order_item['product_unit_id']
      this.setState({product_unit: undefined, sales_order_item: sales_order_item})
      return
    }
    sales_order_item.product_unit_id = product_unit.id

    if (!this.props.sales_order.credit && product_unit.quantity_minimum > sales_order_item.quantity) {
      sales_order_item.quantity = product_unit.quantity_minimum
    }
    this.setState({ product_unit: product_unit, sales_order_item: sales_order_item },
      () => { this.notifyParentOfSubtotalChange(); this.addButton.current.focus(); }
    )
  }

  renderQuantity() {
    const product_unit = this.state.product_unit || { quantity_minimum: 1 }
    return (
      <QuantitySelect
        ref={ this.quantitySelect }
        value={ this.state.sales_order_item.quantity }
        allowDecimal={ true }
        onlyNegativeQuantity={ this.props.sales_order.credit }
        minimum={ this.props.sales_order.credit ? -1000 : product_unit.quantity_minimum }
        initial={ this.props.sales_order.credit ? -1 : product_unit.quantity_minimum }
        onChange={ this.onChangeQuantity } />
    )
  }

  renderUnitPrice() {
    const product_unit = this.state.product_unit
    if (!product_unit) { return }
    return (
      <span>{ asCurrency(product_unit.price) }</span>
    )
  }

  calculateSubtotal() {
    const product_unit = this.state.product_unit
    if (!product_unit) { return 0 }
    return (this.state.sales_order_item.quantity * product_unit.price)
  }

  renderSubtotal() {
    const product_unit = this.state.product_unit
    if (!product_unit) { return }
    return (
      <span>{ asCurrency("" + this.calculateSubtotal()) }</span>
    )
  }

  render() {
    const product_unit = this.state.product_unit
    let rowClass = "order-row list-group-item py-2 align-items-end"
    let buttonClass
    if (product_unit) {
      buttonClass = "btn-primary"
      rowClass += ' bg-warning'
    } else {
      buttonClass = "btn-primary disabled"
    }
    return (
      <div id='new-item-row'>
        <div className={rowClass}>
          <div className='toggle-more-column'>
            <button className='btn btn-sm btn-white text-transparent'><span className='fa fa-ellipsis-v text-light'></span></button>
          </div>
          <div className='quantity-column'>
            { this.renderQuantity() }
          </div>
          <div className='w-100'>
            <div>
              <div className='w-100'>
                <div className="form-check">
                  <input id="show-unavailable-toggle" type="checkbox" onChange={ this.toggle } className="form-check-input" />
                  <label htmlFor="show-unavailable-toggle" className="form-check-label text-muted small">Include Product Units Currently Unavailable</label>
                </div>
              </div>
            </div>
            <ProductUnitSelect
              ref={ this.productUnitSelect }
              seller_id={ this.props.sales_order.seller_id }
              buyer_id={ this.props.sales_order.buyer_id }
              fetcher={ this.state.showUnavailable ? CatalogFetcher.queryAll : CatalogFetcher.queryAvailable }
              value={ product_unit }
              onProductUnitSelected={ this.onProductUnitSelected }
            />
          </div>
          <div className='pl-2'>
            <button
              className={`btn text-nowrap ${buttonClass}`}
              disabled={ !product_unit }
              onClick={ this.onCreate }
              ref={ this.addButton }>
              <span className='d-none d-md-inline'>{ product_unit ? 'Add Item To Order' : 'Search to Add Item'}</span>
              <span className='d-inline d-md-none'><span className='fa fa-plus'></span></span>
            </button>
          </div>
        </div>
      </div>
    )
  }
}
export default SalesOrderItemNew