import React from "react"
import { QuantityInput } from "./QuantityInput"

export class AddToCart extends React.Component {
  constructor(props) {
    super(props)
    this.state = { quantity: props.product_unit.quantity_minimum }

    this.addToCartBackorder = this.addToCartBackorder.bind(this)
    this.addToCart = this.addToCart.bind(this)
  }

  renderQuantityInput() {
    if (!this.props.showPricing || !this.props.cartEnabled) { return }
    const product_unit = this.props.product_unit
    return (
      <div className="align-self-center pr-1">
        <QuantityInput
          id={ product_unit.unit }
          minimum={ product_unit.quantity_minimum }
          unitId={ product_unit.product_unit_id }
          initial={this.state.quantity}
          onChange={this.onChangeQuantity.bind(this)}
        />
      </div>
    )
  }

  onChangeQuantity(dontCare, newValue) {
    this.setState({ quantity: newValue })
  }

  addToCart() {
    const buyer_order_item_params = {
      product_unit_id: this.props.product_unit.product_unit_id,
      quantity: this.state.quantity
    }
    this.props.addItem(buyer_order_item_params, this.props.product_unit.name, this.props.src)
  }

  addToCartBackorder(unavailable_type) {
    const buyer_order_item_params = {
      backorder: true,
      unavailable_type: unavailable_type,
      product_unit_id: this.props.product_unit.product_unit_id,
      quantity: this.state.quantity
    }
    this.props.addItem(buyer_order_item_params, this.props.product_unit.name, this.props.src)
  }

  renderAddToCart(unavailable_type) {
    if (!this.props.cartEnabled || !this.props.showPricing) {
      return (
        <div>
          <a
            className="btn btn-sm btn-outline-secondary text-nowrap"
            href={"/admin/seller/" + this.props.seller_id + "/browse"}
          >
            Sign In to Purchase
          </a>
        </div>
      )
    }
    const colorClass = this.props.colorClass || `btn-${ this.colorClass() }`
    var text = 'Add'
    if ( unavailable_type == 'temporary' ) { text = 'Backorder' }
    if ( unavailable_type == 'preorder' ) { text = 'Preorder' }

    var addToCartFunction = unavailable_type ? this.addToCartBackorder : this.addToCart
    return (
      <div className='w-100'>
        <button type="button"
                className={ `btn ${ colorClass } btn-block` }
                onClick={ () => { addToCartFunction(unavailable_type) } }>
          <span className='px-3'>{ text }</span>
        </button>
      </div>
    )
  }

  colorClass() {
    switch (this.props.product_unit.unavailable_type) {
      case 'temporary':
        return 'warning'
      case 'indefinite':
        return 'danger'
      case 'preorder':
        return 'success'
      default:
        return 'success'
    }
  }

  renderAvailability() {
    const product_unit = this.props.product_unit
    if (!product_unit.next_availability_description) { return }

    const colorClass = this.colorClass()

    return (
      <div className='mb-2'>
        <div className={ `badge badge-pill border border-${ colorClass } text-wrap` }>
          <span className='fa fa-exclamation-triangle mr-2'></span>
          { product_unit.next_availability_description }
        </div>
      </div>
    )
  }

  renderQtyAndButton() {
    const product_unit = this.props.product_unit
    if (product_unit.unavailable_type == 'indefinite') { return }
    return (
      <div className='d-flex'>
        { this.renderQuantityInput() }
        { this.renderAddToCart(product_unit.unavailable_type) }
      </div>
    )
  }

  render() {
    return (
      <div className="product-unit">
        { this.renderAvailability() }
        { this.renderQtyAndButton() }
      </div>
    )
  }
}
