import React from "react"
import { asCurrency } from "../../services/Utils"
import { UncontrolledTooltip } from "reactstrap"

export class ProductUnitPrice extends React.Component {
  constructor(props) {
    super(props)
  }

  showDiscountPrice() {

    const product_unit = this.props.product_unit
    return product_unit.discount_price && Number(product_unit.discount_price) < Number(product_unit.price)
  }

  renderDiscountUnitPrice() {
    const product_unit = this.props.product_unit
    if ( !this.showDiscountPrice() ) { return }
    const discountUnitPrice = asCurrency(product_unit.discount_unit_price)

    return ` ${ discountUnitPrice }`
  }

  renderDiscountPrice() {
    const product_unit = this.props.product_unit
    if ( !this.showDiscountPrice() ) { return }
    const discountPrice = asCurrency(product_unit.discount_price)

    return ` ${ discountPrice }`
  }

  renderCatchWeight() {
    const product_unit = this.props.product_unit
    const unit = product_unit.catch_weight_unit == 'pound' ? 'lb' : product_unit.catch_weight_unit
    const formattedUnitPrice = asCurrency(product_unit.catch_weight_unit_price)
    const formattedPrice = asCurrency(product_unit.discount_price ? product_unit.discount_price : product_unit.price)

    return (
      <span>
        <span className={ this.showDiscountPrice() ? 'text-strike text-muted small' : '' }>
          { formattedUnitPrice }
        </span>
        { this.renderDiscountUnitPrice() }
        <span> / { unit }</span>
        <span id={"info-circle-" + product_unit.product_unit_id} className="fa fa-sm fa-info-circle ml-1 text-dark"></span>
        <UncontrolledTooltip placement="bottom" target={"info-circle-" + product_unit.product_unit_id}>
          { `Est. ${ formattedPrice } total. Actual size and price on final invoice.` }
        </UncontrolledTooltip>
      </span>
    )
  }

  render() {
    if (!this.props.showPricing) { return '' }

    const product_unit = this.props.product_unit

    if (!product_unit.catch_weight_unit_price) {
      return (
        <span>
          <span className={ this.showDiscountPrice() ? 'text-strike text-muted small' : '' }>
            { asCurrency(product_unit.price) }
          </span>
          { this.renderDiscountPrice() }
        </span>
      )
    } else {
      return this.renderCatchWeight()
    }
  }
}
