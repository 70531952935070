import React from "react"

export class QuantitySelect extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.closeOnTab = this.closeOnTab.bind(this)
    this.refocus = this.refocus.bind(this)
    this.quantityInput = React.createRef()
    this.negativePattern = new RegExp(this.props.allowDecimal ? /^-\d*\.?\d*$/ : /^-[0-9]+$/)
    this.positivePattern = new RegExp(this.props.allowDecimal ? /^\d*\.?\d*$/ : /^[0-9]+$/)

    this.state = { showMenu: false }
  }

  checkNumeric(value) {
    const numericPattern = this.props.onlyNegativeQuantity ? this.negativePattern : this.positivePattern
    return numericPattern.test(value)
  }

  onChange(event) {
    this.changeTo(event.target.value)
  }

  changeTo(newValue, closeMenu = true) {
    const isNumeric = this.checkNumeric(newValue)
    if (isNumeric || newValue === '' || newValue === '-') {
      this.setState({ showMenu: false || !closeMenu})
      this.props.onChange(newValue)
    }
  }

  refocus() {
    this.quantityInput.current.select()
    this.setState({ showMenu: false })
  }

  renderQuantityButtons() {
    const mult = this.props.onlyNegativeQuantity ? -1 : 1
    return (
      <div>
        <div className='text-nowrap'>
          <button type='button' onClick={ () => { this.changeTo(1 * mult) }} className='m-1 btn btn-lg btn-outline-secondary'>{ 1 * mult }</button>
          <button type='button' onClick={ () => { this.changeTo(2 * mult) }} className='m-1 btn btn-lg btn-outline-secondary'>{ 2 * mult }</button>
          <button type='button' onClick={ () => { this.changeTo(3 * mult) }} className='m-1 btn btn-lg btn-outline-secondary'>{ 3 * mult }</button>
        </div>
        <div className='text-nowrap'>
          <button type='button' onClick={ () => { this.changeTo(4 * mult) }} className='m-1 btn btn-lg btn-outline-secondary'>{ 4 * mult }</button>
          <button type='button' onClick={ () => { this.changeTo(5 * mult) }} className='m-1 btn btn-lg btn-outline-secondary'>{ 5 * mult }</button>
          <button type='button' onClick={ () => { this.changeTo(6 * mult) }} className='m-1 btn btn-lg btn-outline-secondary'>{ 6 * mult }</button>
        </div>
        <div className='text-nowrap'>
          <button type='button' onClick={ () => { this.changeTo(7 * mult) }} className='m-1 btn btn-lg btn-outline-secondary'>{ 7 * mult }</button>
          <button type='button' onClick={ () => { this.changeTo(8 * mult) }} className='m-1 btn btn-lg btn-outline-secondary'>{ 8 * mult }</button>
          <button type='button' onClick={ () => { this.changeTo(9 * mult) }} className='m-1 btn btn-lg btn-outline-secondary'>{ 9 * mult }</button>
        </div>
      </div>
    )
  }

  closeOnTab(event) {
    var code = event.keyCode || event.which
    if (code == '9') {
      this.setState({showMenu: false})
    }
  }

  render() {
    const showMenu = this.state.showMenu ? 'show' : 'hide'
    const quantityAsNumber = Number(this.props.value)
    let inputClasses = "form-control text-center integer-2 px-1 rounded"
    if ( quantityAsNumber == 0 ) {
      inputClasses += ' alert-danger'
    }

    return (
      <div className="input-group text-nowrap">
        <div className="input-group-prepend">
          <div className={`dropdown-menu drop-shadow-hover ${showMenu}`}>
            <div className='w-100'>
              <button type='button' onClick={ () => { this.changeTo(quantityAsNumber + 1, false) }} className='m-1 btn btn-outline-secondary'>
                <span className='fa fa-chevron-up text-success'></span>
              </button>
              <button type='button' onClick={ () => { this.changeTo(quantityAsNumber - 1, false) }} className='m-1 btn btn-outline-secondary'>
                <span className='fa fa-chevron-down text-danger'></span>
              </button>
              <button onClick={() => { this.setState({showMenu: false})}} className='btn btn-white float-right text-right'>
                <span className='fa fa-times'></span>
              </button>
            </div>
            { this.renderQuantityButtons() }
          </div>
        </div>
        <input ref={ this.quantityInput }
               type="text"
               name='quantity'
               className={ inputClasses }
               value={this.props.value}
               autoComplete='off'
               onClick={() => { this.setState({showMenu: true})}}
               onKeyDown={this.closeOnTab}
               onChange={this.onChange}
               aria-label="Text input with segmented dropdown button" />
      </div>
    )
  }
}
