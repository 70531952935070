import React from "react"
import { QuantityInput } from "./QuantityInput"
import { asCurrency } from "../../services/Utils"

export class CartItem extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)

    this.state = {
      edit: false
    }
  }

  editQuantity(unit, quantity) {
    this.setState({ quantity: quantity })
  }

  toggle() {
    this.setState({
      edit: !this.state.edit,
      quantity: this.props.buyer_order_item.quantity
    })
  }

  confirmEdit() {
    if (this.state.quantity != this.props.buyer_order_item.quantity) {
      this.props.editQuantity(
        this.props.buyer_order_item.id,
        this.state.quantity
      )
    }

    this.toggle()
  }

  renderNotAvailable() {
    if (this.props.buyer_order_item.backorder) { return }
    if (this.props.buyer_order_item.product_unit.is_published) { return }

    return (
      <div className="text-danger mt-1">This product is not currently available</div>
    )
  }

  renderBackordered() {
    const unavailable_type = this.props.buyer_order_item.unavailable_type
    if (!unavailable_type) { return }

    var text;
    if ( unavailable_type == 'temporary' ) { text = 'Backorder' }
    if ( unavailable_type == 'preorder' ) { text = 'Preorder' }

    return (
      <div>
        <span className='badge badge-warning badge-pill'>
          <span className='fa fa-calendar-alt mr-1'></span>
          { text }: Deliver when available
        </span>
      </div>
    )
  }

  renderOriginalSubtotal() {
    if (!this.props.buyer_order_item.original_subtotal) { return }

    return (
      <div className="text-muted small">
        <s>{asCurrency(this.props.buyer_order_item.original_subtotal)}</s>
      </div>
    )
  }

  renderDepositSubtotal() {
    if (this.props.buyer_order_item.deposit_subtotal == 0) { return }

    return (
      <div className="small">
        <span className='fa fa-recycle mr-1'></span>
        {asCurrency(this.props.buyer_order_item.deposit_subtotal)}
      </div>
    )
  }

  render() {
    const buyer_order_item = this.props.buyer_order_item
    return (
      <div className="cart-product-container py-1 px-2 border-top">
        <div className="cart-product-info-container">
          <div className='mb-1'>
           { this.props.showProducer ? (
              <div className='text-muted small'>
                  {buyer_order_item.product_unit.producer_name}
                </div>
              ) : ""
            }
            <div>
              <strong>
                {buyer_order_item.product_unit.product_name}
              </strong>
            </div>
            { this.renderBackordered() }
            { this.renderNotAvailable() }
          </div>
          <div>
            {this.state.edit ? (
              <div>
                <div className='d-inline-block'>
                  <QuantityInput
                    onChange={this.editQuantity.bind(this)}
                    minimum={buyer_order_item.product_unit.quantity_minimum}
                    unitId={buyer_order_item.product_unit_id}
                    initial={buyer_order_item.quantity}
                  />
                </div>
                <button
                  className="btn btn-success btn-sm mx-2"
                  onClick={this.confirmEdit.bind(this)}
                >
                  <span className="fa fa-lg fa-check"></span>
                </button>

                <button
                  id={"remove-product-" + buyer_order_item.id}
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => {
                    this.props.remove(buyer_order_item.id)
                  }}
                >
                  <span className="fa fa-lg fa-trash mr-1"></span>
                  Remove
                </button>
                <button
                  className="btn btn-sm ml-3"
                  onClick={ this.toggle }
                >
                  <span className="fa fa-lg fa-times"></span>
                </button>
              </div>
            ) : (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button className="btn btn-xs btn-outline-dark" style={{width: "31px"}} onClick={ this.toggle }>
                  <span className="h6">{ buyer_order_item.quantity }</span>
                </button>
                <span>
                  <span className="mx-1 text-black-50">×</span>
                  { buyer_order_item.product_unit.unit }
                </span>
              </div>
              <div className="pl-3 text-right">
                {asCurrency(buyer_order_item.subtotal)}
                { this.renderOriginalSubtotal() }
                { this.renderDepositSubtotal() }
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
