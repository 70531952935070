import React from "react"
import { PurchaseOrderItemEdit } from "./PurchaseOrderItemEdit"
import { PurchaseOrderItemNew } from "./PurchaseOrderItemNew"
import { PurchaseOrderService } from "../../services/PurchaseOrderService"
import { asCurrency } from "../../services/Utils"
import _ from "lodash"

export class PurchaseOrderEdit extends React.Component {
  constructor(props) {
    super(props)

    this.onItemCreate = this.onItemCreate.bind(this)
    this.onItemDestroy = this.onItemDestroy.bind(this)
    this.onItemUpdate = this.onItemUpdate.bind(this)
    this.onNewItemSubtotalUpdate = this.onNewItemSubtotalUpdate.bind(this)
    this.onChangePurchaseOrder = this.onChangePurchaseOrder.bind(this)
    this.save = _.debounce(this.save, 1000)
    this.toggleName = this.toggleName.bind(this)

    this.state = {
      purchase_order: props.purchase_order,
      newItemSubtotal: 0
    }
  }

  save() {
    const purchase_order = this.state.purchase_order

    const scrubbed_purchase_order = {
      id: purchase_order.id,
      name: purchase_order.name,
      note_from_buyer: purchase_order.note_from_buyer
    }

    PurchaseOrderService.update(scrubbed_purchase_order).then((purchase_order) => {
      },
      (error) => { toast(error.message, { className: "bg-danger text-white" }) }
    )
  }

  toggleName() {
    this.setState({ showName: !this.state.showName })
  }

  calculateTotalDeposit() {
    const purchase_order = this.state.purchase_order
    const purchase_order_items = purchase_order.buyer_order_items
    const deposit_subtotals = purchase_order_items.map((purchase_order_item) => Number(purchase_order_item.deposit_subtotal))

    return deposit_subtotals.reduce((a,b) => a + b, 0)
  }

  onChangePurchaseOrder(event) {
    let purchase_order = this.state.purchase_order
    purchase_order[event.target.name] = event.target.value
    this.setState({ purchase_order: purchase_order }, this.save)
  }

  onItemCreate(buyer_order_item) {
    let purchase_order = this.state.purchase_order
    const existing = _.find(purchase_order.buyer_order_items, { id: buyer_order_item.id })
    if (existing) {
      const new_buyer_order_items = _.without(purchase_order.buyer_order_items, existing)
      purchase_order.buyer_order_items = new_buyer_order_items
    }
    purchase_order.buyer_order_items.push(buyer_order_item)
    this.setState({ purchase_order: purchase_order, newItemSubtotal: 0 })
  }

  onItemDestroy(buyer_order_item) {
    let purchase_order = this.state.purchase_order
    let item_to_remove = _.find(purchase_order.buyer_order_items, { id: buyer_order_item.id })
    const new_buyer_order_items = _.without(purchase_order.buyer_order_items, item_to_remove)
    purchase_order.buyer_order_items = new_buyer_order_items
    this.setState({ purchase_order: purchase_order })
  }

  onItemUpdate(new_buyer_order_item) {
    let purchase_order = this.state.purchase_order
    let indexToReplace = _.findIndex(purchase_order.buyer_order_items, { id: new_buyer_order_item.id })
    purchase_order.buyer_order_items[indexToReplace] = new_buyer_order_item
    this.setState({ purchase_order: purchase_order })
  }

  onNewItemSubtotalUpdate(newItemSubtotal) {
    this.setState({ newItemSubtotal: newItemSubtotal })
  }

  calculateTotal() {
    const purchase_order = this.state.purchase_order
    const buyer_order_items = purchase_order.buyer_order_items
    const subtotals = buyer_order_items.map((buyer_order_item) => Number(buyer_order_item.total))
    const buyer_order_items_total = subtotals.reduce((a,b) => a + b, 0)

    return "" + (buyer_order_items_total - Number(purchase_order.discount))
  }

  renderItemRows() {
    const items = this.state.purchase_order.buyer_order_items

    if (items.length === 0) {
      return (
        <div className='row'>
          <div className="col-12 p-4 text-center">
            <h4>There are no items in this order</h4>
          </div>
        </div>
      )
    }
    const sortedItems = _.sortBy(items, (item) => { return item.product_unit.product_name })
    return (
      sortedItems.map((order_item) => {
        return (
          <PurchaseOrderItemEdit
            key={ order_item.id }
            purchase_order_item={ order_item }
            showCatchWeight={ this.props.showCatchWeight }
            updatedPurchaseOrderItem={ this.onItemUpdate }
            deletedPurchaseOrderItem={ this.onItemDestroy }
          />
        )
      })
    )
  }

  renderDiscountRow() {
    const purchase_order = this.state.purchase_order
    if (!purchase_order.discount || purchase_order.discount == 0) { return }
    return (
      <div className="order-row d-flex py-2 bg-light">
          <div className="w-100 text-right">
            <span className='font-weight-bold h5'>Discount</span>
          </div>
          <div className="subtotal-price-column">
            <span clasName='font-weight-bold h5'>{ asCurrency(purchase_order.discount) }</span>
          </div>
      </div>
    )
  }

  renderSubtotalRow() {
    const items = this.state.purchase_order.buyer_order_items
    const subtotal = _.sumBy(items, (item) => Number(item.subtotal) )
    return (
      <div id='order-subtotal' className="order-row d-flex bg-light py-1">
        <div className='font-weight-bold w-100 text-right'>Subtotal</div>
        <div className='font-weight-bold subtotal-price-column'>{ asCurrency(subtotal) }</div>
      </div>
    )
  }

  renderDepositRow() {
    const total = this.calculateTotalDeposit()
    if ( total == 0 ) { return }
    return (
      <div id='order-deposit' className="order-row d-flex bg-light py-1">
        <div className='font-weight-bold w-100 text-right'>Deposit</div>
        <div className='font-weight-bold subtotal-price-column'>
          <span className='fa fa-recycle mr-1'></span>
          { asCurrency(total) }
        </div>
      </div>
    )
  }

  renderTotalRow() {
    const purchase_order = this.state.purchase_order
    const total = this.calculateTotal()
    return (
      <div id='order-total' className='order-row d-flex bg-light py-2'>
        <div className="w-100 text-right">
          <span className='font-weight-bold h5'>Total</span>
        </div>
        <div className="subtotal-price-column">
          <span className='font-weight-bold h5'>{ asCurrency(total) }</span>
        </div>
      </div>
    )
  }

  renderName() {
    let purchaseOrderName
    let buttonText = "Set"
    if (this.state.showName) {
      buttonText = "Done"
      purchaseOrderName = (
        <input
          id="purchase_order_name"
          type="text"
          name="name"
          onChange={ this.onChangePurchaseOrder }
          value={ this.state.purchase_order.name }
          className="form-control"
        />
      )
    } else if (this.state.purchase_order.name) {
      buttonText = "Change"
      purchaseOrderName = (
        <span>{ this.state.purchase_order.name }</span>
      )
    }
    return (
      <div className="d-flex">
        <div className="mr-2 align-self-center">
          <b>Purchase Order ID for Invoice: </b>
        </div>
        <div className="align-self-center mr-2">
          { purchaseOrderName }
        </div>
        <div className="align-self-center">
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={this.toggleName} >
              { buttonText }
          </button>
        </div>
      </div>
    )
  }

  renderSubmitButton() {
    const purchase_order = this.state.purchase_order
    if (purchase_order.status !== "draft" || purchase_order.buyer_order_items.length == 0) { return }
    return (
      <div className="mt-2 text-right">
        <a className='btn btn-primary btn-lg' href={`/admin/purchase_orders/${purchase_order.id}/checkout`}>
          Review and Checkout...
        </a>
      </div>
    )
  }

  render() {
    const purchase_order = this.state.purchase_order
    return (
      <div id='purchase-order-edit' className='list-group'>
        <PurchaseOrderItemNew
          purchase_order={ this.props.purchase_order }
          createdPurchaseOrderItem={ this.onItemCreate }
          onSubtotalUpdate={ this.onNewItemSubtotalUpdate }
        />
        <div className='order-row py-1 d-flex bg-light font-weight-bold align-items-baseline'>
          <div className='toggle-more-column'>
            <button className='btn btn-sm btn-white disabled'><span className='fa fa-times text-light'></span></button>
          </div>
          <div className='quantity-column text-center'>Qty</div>
          <div className='item-column'>Item</div>
          <div className='d-flex no-gutters'>
            <div className='unit-price-column'>Unit Price</div>
            <div className='subtotal-price-column'>Subtotal</div>
          </div>
        </div>
        <div>
          { this.renderItemRows() }
          { this.renderSubtotalRow() }
          { this.renderDepositRow() }
          { this.renderDiscountRow() }
          { this.renderTotalRow() }
        </div>
        { this.renderSubmitButton() }
      </div>
    )
  }
}
export default PurchaseOrderEdit