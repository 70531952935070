import { HTTP } from "./Connection"

export class InventoryForecastService {

  static query (buyer_order_id) {
    return new Promise((resolve, reject) => {
      const path = `/api/buyer_orders/${ buyer_order_id }/inventory_forecasts`
      HTTP.get(path).then(resolve, reject)
    })
  }
}
