import { HTTP } from "./Connection"

export class BuyerOrderService {

  static create (seller_id, buyer_id) {
    return new Promise((resolve, reject) => {
      const order = { buyer_id: buyer_id, seller_id: seller_id }
      const payload = { buyer_order: order }
      const url = "/api/buyer_orders"

      HTTP.post(url, payload).then(resolve, reject)
    })
  }

  static update (buyer_order) {
    return new Promise((resolve, reject) => {
      const payload = { buyer_order: buyer_order }
      const url = "/api/buyer_orders/" + buyer_order.id

      HTTP.put(url, payload).then(resolve, reject)
    })
  }
}
