import React from "react"
import { isTomorrow, isToday } from "../../services/Utils"

export class DeliveryDatePicker extends React.Component {
  constructor(props) {
    super(props)

    this.chooseDate = this.chooseDate.bind(this)
    this.toggleDatePicker = this.toggleDatePicker.bind(this)

    this.state = {
      showDatePicker: true
    }
  }

  chooseDate(event) {
    this.props.onChange(event)
  }

  toggleDatePicker() {
    this.setState({showDatePicker: !this.state.showDatePicker})
  }

  formatDate(date_string) {
    if (!date_string) { return 'Please schedule for me' }
    if (isTomorrow(date_string)) { return 'Tomorrow' }

    let date = new Date(date_string + 'T12:00:00Z')
    return date.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })
  }

  renderButtons() {
    return (
      Object.keys(this.props.available_days).map((date) => {
        const on = this.props.available_days[date]
        return (
          <div className='calendar-day' key={date}>
            { on ? this.renderActiveButton(date) : this.renderDisabledButton(date) }
          </div>
        )
      })
    )
  }

  monthName(string_date) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    const index = new Number(string_date.slice(5, 7)) - 1
    return months[index]
  }

  renderDisabledButton(date) {
    const colorClass = isToday(date) ? 'btn-outline-info border-info' : 'border bg-light'
    return (
      <button className={`btn btn-block h-100 px-0 ${ colorClass}`} disabled='disabled'>
        <span className='fa fa-times fa-sm'></span>
      </button>
    )
  }

  renderActiveButton(date) {
    const active = this.props.requested_on == date ? ' active' : ''
    const colorClass = isTomorrow(date) ? 'btn-outline-warning' : 'btn-outline-primary'

    return (
      <button className={`btn btn-block ${colorClass} px-0${ active }`} value={date} onClick={ this.chooseDate }>
        <div className='small'>
          { this.monthName(date) }
        </div>
        <div>
          { Number(date.slice(8, 10)) }
        </div>
      </button>
    )
  }

  renderDropdown() {
    if (!this.state.showDatePicker) { return this.formatDate(this.props.requested_on) }

    const active = !this.props.requested_on ? ' active' : ''
    return (
      <div className='border rounded bg-light p-2'>
        <div>
          <div className='row no-gutters font-weight-bold'>
            <div className='col text-center'>Su</div>
            <div className='col text-center'>M</div>
            <div className='col text-center'>Tu</div>
            <div className='col text-center'>W</div>
            <div className='col text-center'>Th</div>
            <div className='col text-center'>F</div>
            <div className='col text-center'>Sa</div>
          </div>
          <div className='row no-gutters'>
            { this.renderButtons() }
          </div>
          <div className='row no-gutters mt-3'>
            <button className={`btn btn-block btn-outline-primary px-0${active}`} value='' onClick={ this.chooseDate }>
              <div className='small'>
                Please schedule for me
              </div>
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div id='delivery-date-picker'>
        <div className='d-flex d-md-block flex-wrap mt-3'>
          <div className='align-self-center'>
            <button className='btn font-weight-bold p-0 dropdown-toggle' onClick={ this.toggleDatePicker }>Request a Delivery Date</button>
          </div>
        </div>
        { this.renderDropdown() }
      </div>
    )
  }
}
