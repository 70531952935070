import React from "react"
import { stripZeros } from '../../services/Utils'

export class OrderInfo extends React.Component {
  constructor(props) {
    super(props)
  }

  renderStatusIcon(status) {
    if (status == 'draft') {
      return (
        <span className='badge badge-pill badge-warning'>
          <span className='fa fa-angle-right'></span>
        </span>
      )
    } else {
      return (
        <span className='badge badge-pill badge-info'>
          <span className='fa fa-angle-right'></span>
          <span className='fa fa-angle-right'></span>
        </span>
      )
    }
  }

  renderDate(date) {
    if (date) {
      const options = { timeZone: 'UTC', weekday: 'short', month: 'numeric', day: 'numeric' }
      const formattedDate = new Date(date).toLocaleDateString("en-US", options).replace(',','')
      return (
        <span className='badge badge-pill border text-dark'>
          <span className='far fa-calendar mr-2'/>
          { formattedDate }
        </span>
      )
    } else {
      return (
        <span className='badge badge-pill badge-warning'>
          <span className='far fa-calendar mr-2'/>
          Date Not Set
        </span>
      )
    }

  }

  render() {
    const order = this.props.order
    return (
      <span>
        <span>#{ order.id }</span>
        <span className='mx-1'>{ this.renderStatusIcon(order.status) }</span>
        { this.renderDate(order.fulfillment_expected_on) }
        <span className='badge badge-secondary ml-1'>{ stripZeros(order.total_quantity) } </span>
      </span>
    )
  }
}
export default OrderInfo