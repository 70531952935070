var findAndCreateTimeout = function() {
  var timeoutRequestElement = document.querySelector('.timeout-request')
  if (!timeoutRequestElement) { return }

  var pollPath = timeoutRequestElement.dataset.path
  var ajaxRequest = function() { Rails.ajax({url: pollPath, type: 'GET'}) }

  var msDelay = Number(timeoutRequestElement.dataset.msDelay)
  setTimeout(ajaxRequest, msDelay)
}

document.addEventListener('turbo:load', findAndCreateTimeout)
