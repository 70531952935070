import { HTTP } from "./Connection"

export class OrderItemMoveService {
  static create (sales_order_item_id, move_attributes) {
    return new Promise((resolve, reject) => {
      const path = "/api/buyer_order_items/" + sales_order_item_id + "/order_item_moves"
      const payload = { order_item_move: move_attributes }
      HTTP.post(path, payload).then(resolve, reject)
    })
  }
}
