import React from "react"
import Select, { components } from 'react-select';

const MultiValue = (name) => {
  return (
    (innerProps) => {
      const priceRule = innerProps.data
      const children = (<span><input type="hidden" value={ priceRule.value } name={ name } />{ priceRule.label }</span>)
      return <components.MultiValue {...innerProps} children={ children }/>
    }
  )
}

export class ExpectedOnSelect extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <Select
        isMulti={ true }
        components={ { MultiValue: MultiValue(this.props.name) } }
        placeholder="Choose Fulfillment Dates..."
        closeMenuOnSelect={ false }
        onChange={this.handleOnChange}
        options={ this.props.options }
      />
    );
  }
}
export default ExpectedOnSelect
