import React from "react"
import { ProductCatalog } from "./ProductCatalog"
import { CatalogFetcher } from "../../services/CatalogFetcher"

export class PublicCatalog extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const fetcher = (page, batch, params) => {
      return CatalogFetcher.queryPublic(
        this.props.seller.slug,
        page,
        batch,
        params
      )
    }

    return (
      <ProductCatalog
        fetcher={fetcher}
        producers={this.props.producers}
        categories={this.props.categories}
        collections={this.props.collections}
        preloaded_products={this.props.preloaded_products}
        seller={this.props.seller}
        showProducer={this.props.showProducer}
        showPricing={this.props.showPricing}
        type="buyer"
      />
    )
  }
}
export default PublicCatalog