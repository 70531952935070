document.addEventListener('stripe:add-setup-intent', () => {
  const setupIntentElement = document.getElementById('buyer-setup-intent')
  if ( setupIntentElement ) {
    const published_key = setupIntentElement.dataset.pk
    const stripe = Stripe(published_key);

    const elements = stripe.elements({clientSecret: setupIntentElement.dataset.clientSecret})

    const options = { fields: { billingDetails: { billingName: 'auto'}}}
    const paymentElement = elements.create('payment', options)

    paymentElement.mount(setupIntentElement);

    const form = document.getElementById('setup-intent-form');

    form.addEventListener('submit', async (event) => {
      event.preventDefault();

      const {error} = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: setupIntentElement.dataset.returnTo
        }
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        const messageContainer = document.querySelector('#error-message');
        messageContainer.textContent = error.message;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    });

  }
})
