import { HTTP } from "./Connection"

export class SalesOrderItemService {

  static update (sales_order_item) {
    return new Promise((resolve, reject) => {
      const id = sales_order_item.id
      HTTP.put("/api/buyer_order_items/" + id, sales_order_item).then(resolve, reject)
    })
  }

  static destroy (id) {
    return new Promise((resolve, reject) => {
      HTTP.delete("/api/buyer_order_items/" + id).then(resolve, reject)
    })
  }

  static create (sales_order_item) {
    return new Promise((resolve, reject) => {
      const url = "/api/buyer_order_items"
      HTTP.post(url, sales_order_item).then(resolve, reject)
    })
  }
}
