import { HTTP } from "./Connection"

export class PurchaseOrderService {

  static create (buyer_id, seller_id, requested_on) {
    return new Promise((resolve, reject) => {
      const purchase_order = { purchase_order: { seller_id: seller_id,
                                                 buyer_id: buyer_id,
                                                 requested_on: requested_on } }
      const url = "/api/purchase_orders"

      HTTP.post(url, purchase_order).then(resolve, reject)
    })
  }

  static update (purchase_order) {
    return new Promise((resolve, reject) => {
      const payload = { purchase_order: purchase_order }
      const url = "/api/purchase_orders/" + purchase_order.id

      HTTP.put(url, payload).then(resolve, reject)
    })
  }
}
