document.addEventListener("turbo:load", function(){
  $(function () {
    $(".chosen-select:not([multiple]").selectize({ onDropdownOpen: function () {
      this.clear();
    }, selectOnTab: false, allowEmptyOption: true });
    $('.chosen-select[multiple="multiple"]').selectize({ plugins: ['remove_button'], selectOnTab: false, allowEmptyOption: true });
  });

  $("#existing-order-checker").selectize({
    onDropdownOpen: function () { this.clear() },
    selectOnTab: false,
    allowEmptyOption: true,
    onChange: function(value) {
      if (value) {
        var path = this.$input.data('path')
        $.get(path, {'buyer_order[buyer_id]':value}, null, 'script' );
      }
    }
  });
  $("#chooseAllBuyerCategories").on('click', function() {
    var options = Object.values($('#buyer_category_id')[0].selectize.options)
    options.sort((a, b) => { return a.$order - b.$order; });
    var optionsArray = options.map(function(obj) { return obj.value })
    var selectized = $('#buyer_category_id')[0].selectize
    selectized.setValue(optionsArray)
  })

  $("#chooseNoBuyerCategories").on('click', function() {
    $('#buyer_category_id')[0].selectize.setValue([])
  })

  $("#chooseAllBuyers").on('click', function() {
    var options = Object.values($('#buyer_id')[0].selectize.options)
    options.sort((a, b) => { return a.$order - b.$order; });
    var optionsArray = options.map(function(obj) { return obj.value })
    var selectized = $('#buyer_id')[0].selectize
    selectized.setValue(optionsArray)
  })

  $("#chooseNoBuyers").on('click', function() {
    $('#buyer_id')[0].selectize.setValue([])
  })

  $("#chooseAllProducers").on('click', function() {
    var options = Object.values($('#producer_id')[0].selectize.options)
    options.sort((a, b) => { return a.$order - b.$order; });
    var optionsArray = options.map(function(obj) { return obj.value })
    var selectized = $('#producer_id')[0].selectize
    selectized.setValue(optionsArray)
  })

  $("#chooseNoProducers").on('click', function() {
    $('#producer_id')[0].selectize.setValue([])
  })
  $("#chooseAllCategories").on('click', function() {
    var options = Object.values($('#category_id')[0].selectize.options)
    options.sort((a, b) => { return a.$order - b.$order; });
    var optionsArray = options.map(function(obj) { return obj.value })
    var selectized = $('#category_id')[0].selectize
    selectized.setValue(optionsArray)
  })
  $("#chooseNoCategories").on('click', function() {
    $('#category_id')[0].selectize.setValue([])
  })

  $("#chooseNoSellers").on('click', function() {
    $('#seller_id')[0].selectize.setValue([])
  })
  $("#chooseAllSellers").on('click', function() {
    var options = Object.values($('#seller_id')[0].selectize.options)
    options.sort((a, b) => { return a.$order - b.$order; });
    var optionsArray = options.map(function(obj) { return obj.value })
    var selectized = $('#seller_id')[0].selectize
    selectized.setValue(optionsArray)
  })

  $("#chooseMktSellers").on('click', function(event) {
    var ids = event.target.dataset.ids.split(',')
    var selectized = $('#seller_id')[0].selectize

    for (var i = 0; i < ids.length; i++) {
      selectized.addItem(ids[i]);
    }
  })
});

document.addEventListener("turbo:before-cache", function(){
  $('.selectized').each(function () {
    this.selectize.destroy();
    var selectedOption = this.querySelector("option[selected]");
    // destroying removes the selected value, so we have to manually reset it
    if (selectedOption) {
      this.value = selectedOption.value;
    } else if (!this.multiple) {
      this.value = this.options[0].value;
    }
  });
});
