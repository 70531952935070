document.addEventListener('turbo:load', () => {
  if ( !document.getElementById('edit-dashboard') ) { return }

  const left = document.getElementById('left-column')
  const center = document.getElementById('center-column')
  const right = document.getElementById('right-column')
  const unused = document.getElementById('unused-column')

  const movedDashboard = function (evt) {
    const payload = {
      dashboard: evt.item.dataset.name,
      column: evt.to.dataset.column || '',
      position: evt.newIndex
    }

    const data = new URLSearchParams(payload).toString()
    Rails.ajax({
      url: "/admin/dashboard",
      type: "PATCH",
      data: data
    })
	}

  new Sortable(left, {
    group: 'shared',
    animation: 150,
    emptyInsertThreshold: 20,
    ghostClass: 'list-group-item-primary',
    onEnd: movedDashboard
  });

  new Sortable(center, {
    group: 'shared',
    animation: 150,
    emptyInsertThreshold: 20,
    ghostClass:'list-group-item-primary',
    onEnd: movedDashboard
  });

  new Sortable(right, {
    group: 'shared',
    animation: 150,
    emptyInsertThreshold: 20,
    ghostClass: 'list-group-item-primary',
    onEnd: movedDashboard
  });

  new Sortable(unused, {
      group: 'shared',
      filter: '.no-drag',
      animation: 150,
      emptyInsertThreshold: 20,
      ghostClass: 'list-group-item-primary',
      onEnd: movedDashboard
  });

})
