import React from "react"
import Fuse from 'fuse.js'
import ahoy from "ahoy.js";

export class SearchBar extends React.Component {
  constructor(props) {
    super(props)

    const options = {
      includeScore: true,
      threshold: 0.2,
      keys: [ { name: 'name', weight: 0.5 },
              { name: 'subname', weight: 0.5 } ]
    }
    const children = this.props.collections.flatMap((x) => {
      return x.current_children.map((y) => {
        return {slug: x.slug,
          name: x.name,
          subslug: y.slug,
          subname: y.name }
      })
    })
    this.pickCollection = this.pickCollection.bind(this)
    this.onChange = this.onChange.bind(this)

    const all = this.props.collections.concat(children)

    this.fuse = new Fuse(all, options)
  }

  pickCollection(parentSlug, slug) {
    if ( parentSlug ) { this.props.setCollection(parentSlug) }
    if ( slug ) { this.props.setSubcollection(slug, parentSlug) }
    const props = { language: "JavaScript", slug: slug, parentSlug: parentSlug, term: this.props.value  }
    ahoy.track("Collection Search Click", props);
    this.setState({ value: ''})
  }

  onChange(event) {
    const term = event.target.value
    this.props.onChange(term)
  }

  renderMatchingCollections() {
    const items = this.fuse.search(this.props.value).slice(0,5)

    const collections = items.flatMap((x) => x.item )
    if (collections.length == 0) { return (<div id='fuzzy-placeholder'> </div>) }

    return (
      <div className='btn-group d-flex flex-wrap'>{
        collections.map((collection) => {
          return (
            <button key={ collection.slug + '-' + collection.subslug } className='text-nowrap btn btn-sm border flex-grow-0' onClick={() => this.pickCollection(collection.slug, collection.subslug) }>
              <span className='fa fa-filter fa-sm text-secondary mr-1'></span>
              { [collection.name, collection.subname].filter(Boolean).join(' - ') }
            </button>
          )
        })}
      </div>
    )

  }


  render() {
    return (
      <div>
        <div className='input-group'>
          <input
            id="search-input"
            className="form-control form-control-lg"
            name="text-search"
            placeholder="Search..."
            autoComplete='off'
            onChange={this.onChange}
            value={this.props.value}
          />
        </div>
        { this.renderMatchingCollections() }
      </div>
    )
  }
}
